import React, { useState } from "react";
import { Button } from "antd";

import TEXT from "../../config/text";
import useTranslation from "../../hooks/useTranslation";
import ActivateDeviceModal from "../activationPage/ActivateDeviceModal";
import style from "../activationPage/style/payment.module.scss";
import "./ActivateDevice.scss";

const ActivateDevice = () => {
  const [visibleActivateDeviceModal, setVisibleActivateDeviceModal] =
    useState(false);
  const translation = useTranslation();

  const handleText = (text) => {
    if (translation?.pages?.activation?.[text]) {
      return translation?.pages?.activation?.[text];
    }

    return TEXT?.pages?.activation?.[text];
  };
  return (
    <div className="activate-device-2">
      {/* Active Pop up */}
      <div className="activate-device">
        <section className="activation-section">
          <p className={style["text-voucher"]}>
            {handleText("activate_for_qa_description")}
          </p>

          <Button onClick={() => setVisibleActivateDeviceModal(true)}>
            {handleText("activate_qa_button_text")}
          </Button>
        </section>
      </div>

      {visibleActivateDeviceModal && (
        <ActivateDeviceModal
          visible={visibleActivateDeviceModal}
          onClose={() => setVisibleActivateDeviceModal(false)}
        />
      )}
    </div>
  );
};

export default ActivateDevice;
